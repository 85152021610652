/**
 * @file          LoadingFallback.js - Loading component to fallback when the app chunks are being downloaded.
 *
 * @author        Juan Cols <juan@plurimorph.com>
 *
 * @copyright
 *                Copyright © 2021 Plurimorph OU.
 *                All Rights Reserved.
 *                Registry number 16067377.
 *
 **********************************************************************************************************************/

/************************************************* IMPORTED LIBRARIES *************************************************/
import React from "react";
import lottie from "lottie-web/build/player/lottie_light"; // Light version of lottie-web

// Custom Components
import logo from "../images/logo.svg";

// Lottie loading animation taken from: https://lottiefiles.com/85646-loading-dots-blue
import lottieLoading from "../animations/lottie-four-dots-blue.json";

/************************************************ CONSTANTS DEFINITION ************************************************/

/******************************************** REACT COMPONENT IMPLEMENTATION ******************************************/
class LoadingFallback extends React.Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.querySelector("#loading"),
      animationData: lottieLoading,
      renderer: "svg", // "canvas", "html"
      loop: true,
      autoplay: true,
    });
  }

  render() {
    return (
      <div class="flex h-screen bg-blue-200">
        <div class="m-auto">
          <img
            className="h-10 mx-auto my-auto"
            src={logo}
            alt="Plurimorph morphing sphere logo in navy blue and light blue."
          />
          <div id="loading" className="h-32 -mt-10"></div>
        </div>
      </div>
    );
  }
}

export default LoadingFallback;
