/**
 * @file          App.js - Main App entry point.
 *
 * @author        Juan Cols <juan@plurimorph.com>
 *
 * @copyright
 *                Copyright © 2021 Plurimorph OU.
 *                All Rights Reserved.
 *                Registry number 16067377
 *
 **********************************************************************************************************************/

/************************************************** IMPORTED ELEMENTS *************************************************/
import "./styles/main.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect, lazy, Suspense } from "react";
import ReactGA from "react-ga4";
import LoadingFallback from "./components/LoadingFallback";

// Custom Components
const LandingPage = lazy(() => import("./components/LandingPage"));
const ContactPage = lazy(() => import("./components/ContactPage"));
const TermsPage = lazy(() => import("./components/TermsPage"));
const PrivacyPage = lazy(() => import("./components/PrivacyPage"));
const CookiePage = lazy(() => import("./components/CookiePage"));

/************************************************ CONSTANTS DEFINITION ************************************************/
// Google Analytics ID (taken from GA Dashboard: Admin > Data Streams)
const MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

/******************************************** REACT COMPONENT IMPLEMENTATION ******************************************/
function App() {
  useEffect(() => {
    // Initialize Google Analytics only in a PRODUCTION environment
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize(MEASUREMENT_ID);
      // Report Page view at loading
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Suspense fallback={LoadingFallback}>
          <Switch>
            {/* LANDING PAGE */}
            <Route path="/" exact component={LandingPage} />
            {/* CONTACT PAGE */}
            <Route path="/contact" component={ContactPage} />
            {/* TERMS & CONDITIONS PAGE */}
            <Route path="/terms" component={TermsPage} />
            {/* PRIVACY POLICY PAGE */}
            <Route path="/privacy" component={PrivacyPage} />
            {/* COOKIE POLICY PAGE */}
            <Route path="/cookie" component={CookiePage} />
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
